import * as React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const AirlineItem = (props: any) => {
  var airline = props.airline;
  const imageURL = airline?.node?.publicURL;
  return (
    <div className="logo-wrapper">
      <img className="airlines-logo" alt="" src={imageURL} />
    </div>
  );
};

export default AirlineItem;
