import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { Link, PageProps } from "gatsby";

const BannerSlider = (props: any) => {
  var banners = props.banners;
  const topSlidersCount = banners.length;
  const [counter, setCounter] = useState(0);
  const timeoutRef = useRef(null);

  function nextSlide() {
    if (counter < topSlidersCount - 1 && counter >= 0) {
      setCounter(counter + 1);
    } else {
      setCounter(0);
    }
  }
  function prevSlide() {
    if (counter > 0 && counter <= topSlidersCount - 1) {
      setCounter(counter - 1);
    } else {
      setCounter(topSlidersCount - 1);
    }
  }
  useEffect(() => {
    const timeout = setInterval(nextSlide, 6000);
    return () => {
      clearInterval(timeout);
    };
  });
  return (
    <div className="slider-wrapper" style={{ height: "100%" }}>
      {banners.map((banner, index) => {
        var banner = banner.frontmatter;
        return (
          <div
            key={index}
            className={index === counter ? "slider-item active" : "slider-item"}
            style={{
              backgroundImage: "url(" + banner.hero.publicURL + ")",
              backgroundSize: "cover",
            }}
          >
            <div className="overlay" />
            <div className="container">
              <div className="row">
                <h1>{banner?.heading}</h1>
              </div>
            </div>
          </div>
        );
      })}
      <div className="slider-control">
        <div className="container">
          <div className="slider-wrapper">
            <button className="nav-left" onClick={() => prevSlide()}>
              <svg
                width={32}
                height={32}
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.5467 22.12L14.4401 16L20.5467 9.88L18.6667 8L10.6667 16L18.6667 24L20.5467 22.12Z"
                  fill="white"
                />
              </svg>
            </button>
            <span className="slider-counter">
              {banners.map((banner, index) => {
                return (
                  <span
                    key={index}
                    className={index === counter ? "dot active" : "dot"}
                    onClick={() => {
                      setCounter(index);
                    }}
                  />
                );
              })}
            </span>
            <button className="nav-right" onClick={() => nextSlide()}>
              <svg
                width={32}
                height={32}
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.4533 22.12L17.5599 16L11.4533 9.88L13.3333 8L21.3333 16L13.3333 24L11.4533 22.12Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerSlider;
