import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
// import axios from "axios";
import { Amplify, API } from "aws-amplify";
import awsExports from "./../aws-exports";
Amplify.configure(awsExports);

import contact from "../images/contact.svg";
import { checkPhoneNumberValid } from "../utils/utils";
import {
  hubspotSubscriptionType,
  sendgridConfig,
} from "../utils/contact-newsletter-config";
import email from "../images/email.png";
import location from "../images/location.png";

const ContactUs = (props: any) => {
  const [showLoader, setShowLoader] = useState(false);
  const contactNewsletterEnv = sendgridConfig?.env;
  const ValidationSchema = Yup.object().shape({
    FNAME: Yup.string().required("Required"),
    MESSAGE: Yup.string().required("Required"),
    EMAIL: Yup.string().email("Invalid email").required("Required"),
    PHONE: Yup.string()
      .required("Required")
      .test("test-name", "Invalid phone number", (value) => {
        if (value) {
          return checkPhoneNumberValid(value);
        } else {
          return false;
        }
      }),
    COMPANY_NAME: Yup.string(),
    COMPANY_WEBSITE: Yup.string(),
  });

  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState<any>();
  const [userIpAddress, setUserIpAddress] = useState("");
  const [userLocationName, setUserLocationName] = useState("");

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((ipResponse) => {
        if (ipResponse && ipResponse.ip) {
          setUserIpAddress(ipResponse.ip);
          fetch("https://ipinfo.io/" + ipResponse.ip + "?token=8e63b7e36dfe1d")
            .then((ipApiResponse) => ipApiResponse.json())
            .then((ipApiRes) => {
              console.log("ipApiRes", ipApiRes);
              if (ipApiRes) {
                const userLocation = `${
                  ipApiRes?.city ? ipApiRes?.city + ", " : ""
                }${ipApiRes?.region ? ipApiRes?.region + ", " : ""}${
                  ipApiRes?.country ? ipApiRes?.country : ""
                }`;
                setUserLocationName(userLocation);
              }
            });
        }
      })
      .catch((ipError) => {
        console.log("ipError", ipError);
      });
  }, []);

  const apiHandler = async (values, resetForm) => {
    setShowLoader(true);
    API.post("mailjet", "/mailjet/hubspot/contact", {
      body: {
        type: "Sales",
        subscribeFor: [hubspotSubscriptionType?.["sales-contact"]],
        properties: {
          firstname: values.FNAME,
          phone: values.PHONE,
          email: values.EMAIL,
          message: values.MESSAGE,
          company: values.COMPANY_NAME,
          website: values.COMPANY_WEBSITE,
          address: userLocationName,
          city: userIpAddress,
        },
      },
    })
      .then((res) => {
        // console.log("contactResponse", res);
        if (res.status === 200) {
          setIsValid(true);
          resetForm({});
          setTimeout(() => {
            setIsValid(false);
          }, 10000);
          setShowLoader(false);
        } else {
          setError(res?.message);
          resetForm({});
          setTimeout(() => {
            setError("");
          }, 10000);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        setError("Something went wrong");
        resetForm({});
        setTimeout(() => {
          setError("");
        }, 10000);
        setShowLoader(false);
        console.log("contactError", error);
      });
  };

  return (
    <div>
      <Formik
        initialValues={{
          FNAME: "",
          MESSAGE: "",
          EMAIL: "",
          PHONE: "",
          COMPANY_NAME: "",
          COMPANY_WEBSITE: "",
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setStatus, resetForm }) => {
          // console.log("values", values);
          apiHandler(values, resetForm);
          setIsValid(false);
          setError("");
          // setTimeout(() => {
          //   setIsValid(false);
          //   document
          //     .getElementById("mc-embedded-subscribe-form")
          //     .setAttribute(
          //       "action",
          //       "https://nuflights.us1.list-manage.com/subscribe/post?u=a80ff68362ab1e09f8e602694&amp;id=291a55c460"
          //     );

          //   document.getElementById("mc-embedded-subscribe-form").submit();
          // resetForm({});
          // }, 3000);
        }}
      >
        {({ errors, touched }) => (
          <div className="contact" id="contact">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-12">
                      <p className="sub-text">Contact US </p>
                      <h1>Join NuFlights</h1>
                      <p style={{ marginBottom: 30 }}>
                        Whether you are an Airline or Travel Agency, talk to us
                        to share your requirements. We are committed to your
                        success in your NuFlights journey.
                      </p>
                    </div>
                    <div className="col-xl-7">
                      <div className="d-flex justify-content-start align-items-start">
                        <div>
                          <img
                            style={{ marginTop: 2, width: 40 }}
                            src={location}
                            alt=""
                          />
                        </div>
                        <p style={{ paddingLeft: 10, lineHeight: "24px" }}>
                          NuFlights LLC, 21st Floor, Office No. 21 DD BL 46,
                          Doha Tower / Burj Doha, No. 15, Doha, Qatar.
                        </p>
                      </div>
                    </div>
                    <div className="col-xl-5">
                      <div className="d-flex justify-content-start align-items-start">
                        <div>
                          <img
                            style={{ marginTop: 2, width: 36 }}
                            src={email}
                            alt=""
                          />
                        </div>
                        <p style={{ paddingLeft: 13, lineHeight: "22px" }}>
                          For sales :<br />
                          <a
                            href="mailto:sales@nuflights.com"
                            style={{ textDecoration: "none", fontWeight: 500 }}
                          >
                            sales@nuflights.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <img style={{ marginTop: "20px" }} src={contact} alt="" />
                </div>
                <div className="col-md-6">
                  <Form
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    action="javascript:void(0)"
                    method="PUT"
                    target="_self"
                    className="validate"
                  >
                    <div className="contact-form">
                      <div id="mc_embed_signup" style={{ width: "100%" }}>
                        <div id="mc_embed_signup_scroll">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="form-group mb-3">
                                <div className="mc-field-group">
                                  <label htmlFor="mce-FNAME">
                                    Name <span className="asterisk">*</span>
                                  </label>
                                  <Field
                                    name="FNAME"
                                    className="form-control formInput"
                                    id="mce-FNAME"
                                  />
                                  {errors.FNAME && touched.FNAME ? (
                                    <label
                                      className="error"
                                      id="mce-FNAME-error"
                                    >
                                      {errors.FNAME}
                                    </label>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group mb-3">
                                <div className="mc-field-group">
                                  <label htmlFor="mce-PHONE">
                                    Phone no. with country code{" "}
                                    <span className="asterisk">*</span>
                                  </label>
                                  <Field
                                    name="PHONE"
                                    className="form-control formInput"
                                    id="mce-PHONE"
                                  />
                                  <span className="note-text ">
                                    {" "}
                                    Example: +XX-XXX-XXXXXXX OR +XXX-XXXXXXXXXX
                                  </span>
                                  {errors.PHONE && touched.PHONE ? (
                                    <label
                                      className="error"
                                      id="mce-PHONE-error"
                                    >
                                      {errors.PHONE}
                                    </label>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group mb-3">
                                <div className="mc-field-group">
                                  <label htmlFor="mce-EMAIL">
                                    Email <span className="asterisk">*</span>
                                  </label>
                                  <Field
                                    name="EMAIL"
                                    className="email formInput form-control"
                                    id="mce-EMAIL"
                                  />
                                  {errors.EMAIL && touched.EMAIL ? (
                                    <label
                                      className="error"
                                      id="mce-EMAIL-error"
                                    >
                                      {errors.EMAIL}
                                    </label>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group mb-3">
                                <div className="mc-field-group">
                                  <label htmlFor="mce-EMAIL">
                                    Company Name
                                  </label>
                                  <Field
                                    name="COMPANY_NAME"
                                    className="formInput form-control"
                                    id="mce-COMPANY_NAME"
                                  />
                                  {/* {errors.EMAIL && touched.EMAIL ? (
                                    <label
                                      className="error"
                                      id="mce-EMAIL-error"
                                    >
                                      {errors.EMAIL}
                                    </label>
                                  ) : null} */}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group mb-3">
                                <div className="mc-field-group">
                                  <label htmlFor="mce-EMAIL">
                                    Company Website
                                  </label>
                                  <Field
                                    name="COMPANY_WEBSITE"
                                    className="formInput form-control"
                                    id="mce-COMPANY_WEBSITE"
                                  />
                                  {errors.COMPANY_WEBSITE &&
                                  touched.COMPANY_WEBSITE ? (
                                    <label
                                      className="error"
                                      id="mce-EMAIL-error"
                                    >
                                      {errors.COMPANY_WEBSITE}
                                    </label>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group mb-3">
                                <div className="mc-field-group">
                                  <label htmlFor="mce-MESSAGE">
                                    Your message{" "}
                                    <span className="asterisk">*</span>
                                  </label>
                                  <Field
                                    as="textarea"
                                    name="MESSAGE"
                                    className="form-control formInput"
                                    id="mce-MESSAGE"
                                    cols={20}
                                    rows={5}
                                    placeholder="Type Here"
                                  />
                                  {errors.MESSAGE && touched.MESSAGE ? (
                                    <label
                                      className="error"
                                      id="mce-MESSAGE-error"
                                    >
                                      {errors.MESSAGE}
                                    </label>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group  mb-3">
                                <div id="mce-responses" className="clear">
                                  <div
                                    className="response"
                                    id="mce-error-response"
                                    style={{ display: "none" }}
                                  ></div>
                                  <div
                                    className="response"
                                    id="mce-success-response"
                                    style={{ display: "none" }}
                                  ></div>
                                </div>
                                {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
                                <div
                                  style={{
                                    position: "absolute",
                                    left: "-5000px",
                                  }}
                                  aria-hidden="true"
                                >
                                  <input
                                    type="text"
                                    name="b_9b8a42212cbeab68ff8b99f2f_a1719d594"
                                    tabIndex={-1}
                                  />
                                </div>
                                <div className="clear">
                                  <button
                                    type="submit"
                                    name="subscribe"
                                    id="mc-embedded-subscribe"
                                    className="btn btn-primary"
                                  >
                                    Submit
                                  </button>

                                  {showLoader && (
                                    <div
                                      className="spinner-border text-primary position-relative top-20 left-10"
                                      role="status"
                                    >
                                      <span className="visually-hidden">
                                        Loading...
                                      </span>
                                    </div>
                                  )}
                                </div>
                                {isValid ? (
                                  <div
                                    className="success-message"
                                    style={{
                                      padding: "10px 0",
                                      color: "green",
                                    }}
                                  >
                                    Thank you for your enquiry. We have sent a
                                    confirmation email to you.
                                  </div>
                                ) : null}
                              </div>
                              {error ? (
                                <div
                                  style={{
                                    color: "red",
                                  }}
                                >
                                  {error}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default ContactUs;
