import React, { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ImageSlider = (props: any) => {
  var arrImages = props.images;
  const totalImages = arrImages.length;
  const [currSlide, setCurrSlide] = useState<number>(0);

  function nextSlide(intSlide) {
    if (intSlide === totalImages - 1) {
      setCurrSlide(0);
    } else {
      setCurrSlide(intSlide + 1);
    }
  }
  function prevSlide(intSlide) {
    if (intSlide === 0) {
      setCurrSlide(totalImages - 1);
    } else {
      setCurrSlide(intSlide - 1);
    }
  }
  return (
    <div className="product-slider">
      {arrImages.map((rawImage, index) => {
        return (
          <img
            key={index}
            src={rawImage?.publicURL}
            style={{ width: "100%" }}
            className={
              index === currSlide ? "product-slide active" : "product-slide"
            }
          />
        );
      })}
      <div className="product-slider-control">
        <button className="t-buttons" onClick={() => prevSlide(currSlide)}>
          <svg
            width={11}
            height={16}
            viewBox="0 0 11 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5465 14.12L4.43984 8L10.5465 1.88L8.6665 0L0.666504 8L8.6665 16L10.5465 14.12Z"
              fill="#EF4E58"
            />
          </svg>
        </button>
        {arrImages.map((rawImage, index) => (
          <div
            key={index}
            className={
              index === currSlide ? "product-dot active" : "product-dot"
            }
          />
        ))}
        <button className="t-buttons" onClick={() => nextSlide(currSlide)}>
          <svg
            width={11}
            height={16}
            viewBox="0 0 11 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.453496 14.12L6.56016 8L0.453496 1.88L2.3335 0L10.3335 8L2.3335 16L0.453496 14.12Z"
              fill="#EF4E58"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ImageSlider;
