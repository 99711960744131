import * as React from "react";
import { Link, PageProps } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

const BenefitListItem = (props: any) => {
  var benefit = props.benefit;
  var index = props.index;
  return (
    <div className="col-md-4" key={index}>
      <div className="benefit-item">
        <img src={benefit?.frontmatter?.icon?.publicURL} />
        <h3>{benefit?.frontmatter?.title}</h3>
        <MDXRenderer>{benefit.body}</MDXRenderer>
      </div>
    </div>
  );
};

export default BenefitListItem;
