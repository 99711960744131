import * as React from "react";
import { Link, PageProps } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ImageSlider from "./image_slider";
import { isEmpty } from "../utils/utils";

const ServiceListItem = (props: any) => {
  const service = props.service;
  const featureList = service?.frontmatter?.featureList;
  const imgAlign = service?.frontmatter?.imageAlign;
  const arrImage = service?.frontmatter?.image;

  return (
    <>
      <div className="row mt-5 pb-5">
        {imgAlign === "left" ? (
          !isEmpty(arrImage) && arrImage.length > 1 ? (
            <div className="col-md-6 pt-2">
              <ImageSlider images={arrImage} />
            </div>
          ) : (
            <div className="col-md-6 text-left pt-2">
              {!isEmpty(arrImage) &&
                arrImage.map((rawImage, index) => {
                  return (
                    <img
                      key={index}
                      src={rawImage?.publicURL}
                      className="img-fluid"
                    />
                  );
                })}
            </div>
          )
        ) : null}
        <div className="col-md-6">
          <h3>{service?.frontmatter?.title}</h3>
          <MDXRenderer>{service?.body}</MDXRenderer>
          <div className="sub-title">Feature List</div>
          <ul>
            {featureList.map((item) => (
              <li key={item}> {item} </li>
            ))}
          </ul>
        </div>
        {imgAlign === "right" ? (
          !isEmpty(arrImage) && arrImage.length > 1 ? (
            <div className="col-md-6 pt-2">
              <ImageSlider images={arrImage} />
            </div>
          ) : (
            <div className="col-md-6 text-left pt-2">
              {!isEmpty(arrImage) &&
                arrImage.map((rawImage, index) => {
                  return (
                    <img
                      key={index}
                      src={rawImage?.publicURL}
                      className="img-fluid"
                    />
                  );
                })}
            </div>
          )
        ) : null}
      </div>
    </>
  );
};

export default ServiceListItem;
